// import React, { useState } from 'react';
// import './UserInput.css';

// const UserInput = ({ onSubmit }) => {
//   const [message, setMessage] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onSubmit(message);
//     setMessage('');
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <input
//         type="text"
//         value={message}
//         onChange={(e) => setMessage(e.target.value)}
//         placeholder="Type your message..."
//       />
//       <button type="submit">Send</button>
//     </form>
//   );
// };

// export default UserInput;


import React, { useState, useRef } from 'react';
import './UserInput.css';

const UserInput = ({ onSubmit }) => {
  const [message, setMessage] = useState('');
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      onSubmit(message);
      setMessage('');

      inputRef.current.focus();
    }
  };


return (
    <div className="UserInput">
      <form onSubmit={handleSubmit} className="user-input-form">
        <input
          ref={inputRef}
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          className="user-input-field"
        />
        <button type="submit" className="user-input-button">Send</button>
      </form>
    </div>
  );
};

export default UserInput;
