const fetchUnsplashImage = async (searchTerm) => {
    const apiKey = 'w2WKXFEycxZiVWkKeXwwMenNZX17eiEi4pYx8dS3jRk';
    const url = `https://api.unsplash.com/search/photos?page=1&query=${searchTerm}&client_id=${apiKey}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
  
      if (data && data.results && data.results.length > 0) {
        return data.results[0].urls.regular;
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  
    return null;
  };
  
  export default fetchUnsplashImage;
  