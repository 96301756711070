// export const fetchPlantMood = async (plantMood) => {
//   try {
//     const response = await fetch('/.netlify/functions/fetch-plant-mood', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ plantMood }),
//     });

//     if (!response.ok) {
//       const errorText = await response.text();
//       console.error(`Failed to fetch plant mood (status: ${response.status}): ${errorText}`);
//       throw new Error(`Failed to fetch plant mood (status: ${response.status}): ${errorText}`);
//     }

//     const data = await response.text();
//     return data;
//   } catch (error) {
//     console.error(`Error in fetchPlantMood: ${error.message}`);
//     throw error;
//   }

// };

export const fetchPlantMood = async (sensorData) => {
  try {
    const response = await fetch('/.netlify/functions/fetch-plant-mood', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sensorData }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error(`Failed to fetch plant mood (status: ${response.status}): ${errorText}`);
      throw new Error(`Failed to fetch plant mood (status: ${response.status}): ${errorText}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error(`Error in fetchPlantMood: ${error.message}`);
    throw error;
  }
};


export const fetchPlantData = async (plantName) => {
  try {
    const response = await fetch('/.netlify/functions/fetch-plant-data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ plantName }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error(`Failed to fetch plant data (status: ${response.status}): ${errorText}`);
      throw new Error(`Failed to fetch plant data (status: ${response.status}): ${errorText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error in fetchPlantData: ${error.message}`);
    throw error;
  }
};

export const generateResponse = async (prompt, conversationHistory, plantName, plantNickname, plantData, sensorData, plantMood) => {
  try {
    const response = await fetch('/.netlify/functions/gpt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: prompt, conversationHistory: Array.isArray(conversationHistory) ? conversationHistory : [], plantName, plantNickname, plantData, sensorData , plantMood}),
    });

    console.log("API response:", response);

    if (!response.ok) {
      const errorText = await response.text();
      console.error(`Failed to generate response (status: ${response.status}): ${errorText}`);
      throw new Error(`Failed to generate response (status: ${response.status}): ${errorText}`);
    }

    const data = await response.json();

    console.log("API response data (stringified):", JSON.stringify(data, null, 2));

    if (data.choices && data.choices.length > 0 && data.choices[0].message) {
      return data.choices[0].message.content.trim();
    } else {
      throw new Error('Failed to generate response');
    }
  } catch (error) {
    console.error(`Error in generateResponse: ${error.message}`);
    console.error("Full error response:", error);
    throw error;
  }
};
