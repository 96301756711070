// import React from 'react';
// import './Conversation.css';

// // const Conversation = ({ messages }) => {
// //   return (
// //     <div>
// //       <ul>
// //         {messages.map((message, index) => (
// //           <li key={index}>{message}</li>
// //         ))}
// //       </ul>
// //     </div>
// //   );
// // };


// const Conversation = ({ messages }) => {
//     return (
//       <div className="Conversation">
//         {messages.map((message, index) => (
//           <div
//             key={index}
//             className={`Conversation-message Conversation-message--${message.startsWith('You') ? 'human' : 'plant'}`}
//           >
//             {message}
//           </div>
//         ))}
//       </div>
//     );
//   };
  

// export default Conversation;

import React, { useEffect, useRef } from 'react';
import './Conversation.css';

const Conversation = ({ messages }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  return (
    <div className="Conversation">
      <div className="Chat-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`Conversation-message Conversation-message--${message.sender === 'user' ? 'human' : 'plant'}`}
          >
            {message.sender === 'user' ? 'You: ' : `${message.plantNickname}: `}{message.content}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default Conversation;
