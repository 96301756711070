import React, { useState } from 'react';
import styles from './PlantSelector.module.css';
import { fetchPlantData } from '../utils/gpt'; // Import fetchPlantData function

const PlantSelector = ({ onPlantSelection, onPlantNameChange }) => {
  const [plant, setPlant] = useState('');
  const [nickname, setNickname] = useState('');

  const handleSubmit = async (event) => { // Make handleSubmit async
    event.preventDefault();
    if (plant && nickname) {
      const plantData = await fetchPlantData(plant); // Fetch plant data
      onPlantSelection({ plant, nickname, plantData }); // Pass plant data to onPlantSelection
    }
  };

  const handlePlantChange = (e) => {
    setPlant(e.target.value);
    onPlantNameChange(e.target.value);
  };
  

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Select Your Plant</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <label className={styles.label} htmlFor="plant">
          Choose a plant:
        </label>
        <select
          className={styles.select}
          id="plant"
          value={plant}
          onChange={handlePlantChange}
        >
            <option value="">Choose a plant</option>
            <option value="Aloe Vera">Aloe Vera</option>
            <option value="Areca Palm">Areca Palm</option>
            <option value="Asparagus Fern">Asparagus Fern</option>
            <option value="Bamboo Palm">Bamboo Palm</option>
            <option value="Benjamin Ficus">Benjamin Ficus</option>
            <option value="Boston Fern">Boston Fern</option>
            <option value="Calathea">Calathea</option>
            <option value="Chinese Evergreen">Chinese Evergreen</option>
            <option value="Croton">Croton</option>
            <option value="Dieffenbachia">Dieffenbachia</option>
            <option value="Dracaena Marginata">Dracaena Marginata</option>
            <option value="English Ivy">English Ivy</option>
            <option value="Fiddle Leaf Fig">Fiddle Leaf Fig</option>
            <option value="Golden Pothos">Golden Pothos</option>
            <option value="Heartleaf Philodendron">Heartleaf Philodendron</option>
            <option value="Jade Plant">Jade Plant</option>
            <option value="Kentia Palm">Kentia Palm</option>
            <option value="Lucky Bamboo">Lucky Bamboo</option>
            <option value="Maidenhair Fern">Maidenhair Fern</option>
            <option value="Money Plant">Money Plant</option>
            <option value="Monstera Deliciosa">Monstera Deliciosa</option>
            <option value="Peace Lily">Peace Lily</option>
            <option value="Philodendron Brasil">Philodendron Brasil</option>
            <option value="Pothos">Pothos</option>
            <option value="Rubber Plant">Rubber Plant</option>
            <option value="Schefflera">Schefflera</option>
            <option value="Snake Plant">Snake Plant</option>
            <option value="Spider Plant">Spider Plant</option>
            <option value="Swiss Cheese Plant">Swiss Cheese Plant</option>
            <option value="Umbrella Plant">Umbrella Plant</option>
            <option value="Weeping Fig">Weeping Fig</option>
            <option value="Yucca">Yucca</option>
            <option value="ZZ Plant">ZZ Plant</option>
            </select>

        <label className={styles.label} htmlFor="nickname">
          Give your plant a nickname:
        </label>
        <input
          className={styles.input}
          id="nickname"
          type="text"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
        <button className={styles.submit} type="submit">
          Start Conversation
        </button>
      </form>
    </div>
  );
};

export default PlantSelector;
