import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import './SensorInput.css';

export const initialState = {
  temperature: 70,
  lightIntensity: 50,
  soilMoisture: 50,
  humidity: 50,
  fertilizerConcentration: 50,
};

const SensorInput = ({ onSensorDataChange, showSensorData, toggleMenu, plantData }) => {

  const initialStateMemo = useMemo(() => initialState, []);

  const [temperature, setTemperature] = useState(initialStateMemo.temperature);
  const [lightIntensity, setLightIntensity] = useState(initialStateMemo.lightIntensity);
  const [soilMoisture, setSoilMoisture] = useState(initialStateMemo.soilMoisture);
  const [humidity, setHumidity] = useState(initialStateMemo.humidity);
  const [fertilizerConcentration, setFertilizerConcentration] = useState(initialStateMemo.fertilizerConcentration);
  
  const updateStateFromInitialState = useCallback(() => {
    setTemperature(initialStateMemo.temperature);
    setLightIntensity(initialStateMemo.lightIntensity);
    setSoilMoisture(initialStateMemo.soilMoisture);
    setHumidity(initialStateMemo.humidity);
    setFertilizerConcentration(initialStateMemo.fertilizerConcentration);
  }, [initialStateMemo]);

  useEffect(() => {
    updateStateFromInitialState();
  }, [updateStateFromInitialState]);


    const contentWrapper = useRef(null);
    const handleSensorDataChange = (
      newTemperature,
      newLightIntensity,
      newSoilMoisture,
      newHumidity,
      newFertilizerConcentration
    ) => {
      const lightIntensityDescription = getLightIntensityDescription(newLightIntensity);
      const temperatureDescription = getTemperatureDescription(newTemperature);
      const humidityDescription = getHumidityDescription(newHumidity);
      const soilMoistureDescription = getSoilMoistureDescription(newSoilMoisture);
      const fertilizerConcentrationDescription = getFertilizerConcentrationDescription(newFertilizerConcentration);
    
      onSensorDataChange({
        temperature: newTemperature,
        temperatureDescription,
        lightIntensity: newLightIntensity,
        lightIntensityDescription,
        soilMoisture: newSoilMoisture,
        soilMoistureDescription,
        humidity: newHumidity,
        humidityDescription,
        fertilizerConcentration: newFertilizerConcentration,
        fertilizerConcentrationDescription,
      });
    };
    
  const getSensorDescription = (sensorValue, min, max) => {
    const segment = min * 0.3;
  
    if (sensorValue < min - segment) return "Dangerously Low";
    if (sensorValue >= min - segment && sensorValue < min) return "Too Low";
    if (sensorValue >= min && sensorValue <= max) return "Good";
    if (sensorValue > max && sensorValue < max + segment) return "High";
    return "Too high";
  };

  const getLightIntensityDescription = (lightIntensity) => {
    if (!plantData || !plantData["Light intensity (Lux)"]) return "N/A";
    const [minLight, maxLight] = plantData["Light intensity (Lux)"];
    return getSensorDescription(lightIntensity, minLight, maxLight);
  };

  const getTemperatureDescription = (temperature) => {
    if (!plantData || !plantData["Temperature (°F)"]) return "N/A";
    const [minTemp, maxTemp] = plantData["Temperature (°F)"];
    return getSensorDescription(temperature, minTemp, maxTemp);
  };
  
  const getHumidityDescription = (humidity) => {
    if (!plantData || !plantData["Humidity (%)"]) return "N/A";
    const [minHum, maxHum] = plantData["Humidity (%)"];
    return getSensorDescription(humidity, minHum, maxHum);
  };
  
  const getSoilMoistureDescription = (soilMoisture) => {
    if (!plantData || !plantData["Soil moisture (%)"]) return "N/A";
    const [minSoil, maxSoil] = plantData["Soil moisture (%)"];
    return getSensorDescription(soilMoisture, minSoil, maxSoil);
  };
  
  const getFertilizerConcentrationDescription = (fertilizerConcentration) => {
    if (!plantData || !plantData["Fertilizer concentration (ppm)"]) return "N/A";
    const [minFert, maxFert] = plantData["Fertilizer concentration (ppm)"];
    return getSensorDescription(fertilizerConcentration, minFert, maxFert);
  };  

  const updateSize = () => {
    const sensorInputOpen = document.querySelector('.SensorInput-open');

    if (contentWrapper.current && sensorInputOpen) {
      sensorInputOpen.style.width = `${contentWrapper.current.clientWidth}px`;
      sensorInputOpen.style.height = `${contentWrapper.current.clientHeight}px`;
    }
  };

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return (
    <div className={`SensorInput ${showSensorData ? 'SensorInput-open' : ''}`}>
      <button className="SensorInput-closeButton" onClick={toggleMenu}>×</button>
      <h2 className="SensorInput-title">Sensor Data</h2>
      <div className="SensorInput-container">
        <label htmlFor="temperature">Temperature: {temperature}F</label>
        <input
          id="temperature"
          type="range"
          min="50"
          max="90"
          value={temperature}
          onChange={(e) => {
            const newTemperature = e.target.value;
            setTemperature(newTemperature);
            handleSensorDataChange(
              newTemperature,
              lightIntensity,
              soilMoisture,
              humidity,
              fertilizerConcentration
            );
          }}
        />
        <label htmlFor="lightIntensity">Light Intensity: {lightIntensity}F</label>
        <input
          id="lightIntensity"
          type="range"
          min="0"
          max="10000"
          value={lightIntensity}
          onChange={(e) => {
            const newLightIntensity = e.target.value;
            setLightIntensity(newLightIntensity);
            handleSensorDataChange(
              temperature,
              newLightIntensity,
              soilMoisture,
              humidity,
              fertilizerConcentration
            );
          }}
        />
        <label htmlFor="soilMoisture">Soil Moisture: {soilMoisture}%</label>
        <input
          id="soilMoisture"
          type="range"
          min="1"
          max="100"
          value={soilMoisture}
          onChange={(e) => {
            const newSoilMoisture = e.target.value;
            setSoilMoisture(newSoilMoisture);
            handleSensorDataChange(
              temperature,
              lightIntensity,
              newSoilMoisture,
              humidity,
              fertilizerConcentration
            );
          }}
        />
        <label htmlFor="humidity">Humidity: {humidity}%</label>
        <input
          id="humidity"
          type="range"
          min="20"
          max="90"
          value={humidity}
          onChange={(e) => {
            const newHumidity = e.target.value;
            setHumidity(newHumidity);
            handleSensorDataChange(
              temperature,
              lightIntensity,
              soilMoisture,
              newHumidity,
              fertilizerConcentration
            );
          }}
        />
        <label htmlFor="fertilizerConcentration">Fertilizer Concentration: {fertilizerConcentration} ppm</label>
        <input
          id="fertilizerConcentration"
          type="range"
          min="0"
          max="500"
          value={fertilizerConcentration}
          onChange={(e) => {
            const newFertilizerConcentration = e.target.value;
            setFertilizerConcentration(newFertilizerConcentration);
            handleSensorDataChange(
              temperature,
              lightIntensity,
              soilMoisture,
              humidity,
              newFertilizerConcentration
            );
          }}
        />
        {/* <button onClick={handleUpdateReadings}>Update Readings</button> */}
      </div>
    </div>
  );
  
  
};
  

export default SensorInput;
