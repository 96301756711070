import React, { useState, useEffect , useRef } from 'react';
import './App.css';
import Conversation from './components/Conversation';
import UserInput from './components/UserInput';
import PlantSelector from './components/PlantSelector';
import { generateResponse } from './utils/gpt';
import fetchUnsplashImage from './unsplash';
import SensorInput from './components/SensorInput';
import { fetchPlantData } from './utils/gpt';
import { initialState } from './components/SensorInput';
import { fetchPlantMood } from './utils/gpt';


const App = () => {
  const [messages, setMessages] = useState([]);
  const [sensorData, setSensorData] = useState({
    "Temperature (°F)": initialState.temperature,
    "Light intensity (Lux)": initialState.lightIntensity,
    "Soil moisture (%)": initialState.soilMoisture,
    "Humidity (%)": initialState.humidity,
    "Fertilizer concentration (ppm)": initialState.fertilizerConcentration,
  });
  const [conversationHistory, setConversationHistory] = useState('');
  const [currentScreen, setCurrentScreen] = useState('plantSelector');
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [showSensorData, setShowSensorData] = useState(false);
  const [chatWrapperHeight, setChatWrapperHeight] = useState(null);
  const [contentWrapperHeight, setContentWrapperHeight] = useState(window.innerHeight);
  const [plantData, setPlantData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [_plantMood, setPlantMood] = useState(null);

  const contentWrapperRef = useRef(null);

  // const handleUpdateReadings = async (sensorData) => {
  //   const relevantSensorData = {
  //     temperature: sensorData["Temperature (°F)"],
  //     light: sensorData["Light intensity (Lux)"],
  //     moisture: sensorData["Soil moisture (%)"],
  //     humidity: sensorData["Humidity (%)"],
  //     nutrients: sensorData["Fertilizer concentration (ppm)"],
  //   };
  //   const moodResponse = await fetchPlantMood(relevantSensorData);
  //   setPlantMood(moodResponse);
  // };  

  const toggleSensorInput = () => {
    setShowSensorData(!showSensorData);
  };  
  
  const handleSensorDataChange = (newSensorData) => {
    setSensorData(newSensorData);
  };

  const handleUserInput = async (userMessage) => {
    setMessages([...messages, { sender: 'user', content: userMessage }]);
  
    const plantName = selectedPlant.plant;
    const plantNickname = selectedPlant.nickname;
  
    // Fetch plant mood based on the sensor data
    const currentPlantMood = await fetchPlantMood(sensorData);

    const prompt = `User message: ${userMessage}\nSensor data: ${JSON.stringify(sensorData)}\nPlant ideal data: ${JSON.stringify(plantData)}\nPlant Personality: ${currentPlantMood}\nPlant name: ${plantName}`;
    const plantResponse = await generateResponse(prompt, conversationHistory, plantName, plantNickname, plantData, sensorData, currentPlantMood);
  
    const updatedHistory = [
      ...conversationHistory,
      {
        userMessage: `User message: ${userMessage}`,
        sensorData: `Latest sensor data: ${JSON.stringify(sensorData)}`,
        plantData: `Plant ideal data: ${JSON.stringify(plantData)}`,
        plantMood: `Plant Personality: ${currentPlantMood}`,
        plantResponse: `${plantResponse}`
      },
    ];
  
    setConversationHistory(updatedHistory);
    setMessages((prevMessages) => [...prevMessages, { sender: 'plant', content: plantResponse }]);
  };  

  const [plantBackgroundImage, setPlantBackgroundImage] = useState(null);

  const handlePlantSelection = async (selectedPlant) => {
    setSelectedPlant(selectedPlant);
    const imageUrl = await fetchUnsplashImage(selectedPlant.plant);
    setPlantBackgroundImage(imageUrl);
    setCurrentScreen("conversation");
    const fetchedPlantData = await fetchPlantData(selectedPlant.plant);
    setPlantData(fetchedPlantData);
    const fetchedPlantMood = await fetchPlantMood(sensorData);
    setPlantMood(fetchedPlantMood);
    };
  

  const toggleMenu = () => {
    if (window.innerWidth <= 768) {
      setShowSensorData(!showSensorData);
    }
  };

  const updateChatWrapperHeight = (height) => {
    setChatWrapperHeight(height);
  };
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSensorData(false);
        setContentWrapperHeight(window.innerHeight); // Add this line
      } else {
        setShowSensorData(true);
      }
    };
  
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  useEffect(() => {
    if (contentWrapperRef.current) {
      const contentWrapperHeight = contentWrapperRef.current.offsetHeight;
      updateChatWrapperHeight(contentWrapperHeight);
    }
  }, [contentWrapperRef]);

  useEffect(() => {
    const handleKeyboardShow = (e) => {
      if (window.innerWidth <= 768) {
        setContentWrapperHeight(window.innerHeight - e.endCoordinates.height);
      }
    };
  
    const handleKeyboardHide = () => {
      if (window.innerWidth <= 768) {
        setContentWrapperHeight(window.innerHeight);
      }
    };
  
    // Add listeners for keyboard events on mobile devices
    if ('keyboard' in window) {
      window.keyboard.addListener('keyboardDidShow', handleKeyboardShow);
      window.keyboard.addListener('keyboardDidHide', handleKeyboardHide);
    }
  
    return () => {
      // Cleanup keyboard event listeners
      if ('keyboard' in window) {
        window.keyboard.removeListener('keyboardDidShow', handleKeyboardShow);
        window.keyboard.removeListener('keyboardDidHide', handleKeyboardHide);
      }
    };
  }, []);
  
  
  return (
    <div className="App">
      {currentScreen === "plantSelector" ? (
        <PlantSelector
          onPlantSelection={handlePlantSelection}
          onPlantNameChange={(plantName) => setSelectedPlant({ ...selectedPlant, plant: plantName })} // Add this prop
        />
      ) : (
        <div
          className="content-wrapper"
          ref={contentWrapperRef}
          style={{
            height: contentWrapperHeight, // Add this line
            backgroundImage: plantBackgroundImage
              ? `url(${plantBackgroundImage})`
              : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
        <SensorInput
          onSensorDataChange={handleSensorDataChange}
          showSensorData={showSensorData}
          toggleMenu={toggleSensorInput}
          fetchPlantData={fetchPlantData}
          // handleUpdateReadings={handleUpdateReadings}
          plantData={plantData}
        />
          <div className="chat-wrapper" style={{ height: chatWrapperHeight }}>
            <button className="HamburgerMenu-button" onClick={toggleMenu}>
              <span className="HamburgerMenu-button-line"></span>
              <span className="HamburgerMenu-button-line"></span>
              <span className="HamburgerMenu-button-line"></span>
            </button>
            <Conversation
              messages={messages.map((msg) => ({...msg, plantNickname: selectedPlant.nickname}))}
            />
            <UserInput onSubmit={(message) => handleUserInput(message)} onUpdateHeight={updateChatWrapperHeight} />
          </div>
        </div>
      )}
    </div>
  );
  
};

export default App;